import { FragmentType, makeFragmentData } from '../../gql';
import { betBonusRewardFragment, betCoinRewardFragment, userBonusFragment } from '../../gql/fragment';

export function createCoinRewards(amount: number) {
  return {
    __typename: 'BetCoinReward' as const,
    ...makeFragmentData(
      {
        amount,
      },
      betCoinRewardFragment,
    ),
  };
}

export function createBonusReward(userBonusId: string, userBonus: FragmentType<typeof userBonusFragment>) {
  return {
    __typename: 'BetBonusReward' as const,
    ...makeFragmentData(
      {
        userBonusId,
        userBonus,
      },
      betBonusRewardFragment,
    ),
  };
}
