import { AnimationType } from './table';
import { getRandomFromUUID, getResultFromTable } from './util';

export function conversionAnimationType(num: number): AnimationType {
  let animType = AnimationType.NON;
  switch (num) {
    case 0:
      animType = AnimationType.PHOENIX;
      break;
    case 1:
      animType = AnimationType.NON;
      break;
  }
  return animType;
}

export function getAnimationSelectionTableLot(uuid: string, table: number[]): AnimationType {
  const rand = getRandomFromUUID(uuid, 100);
  const result = getResultFromTable(table, rand);
  return conversionAnimationType(result);
}
