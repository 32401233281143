import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_SPRITE_TEXTURES,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import { IAuthInput } from '../../global.d';
import { setIsMobile, setIsSoundOn, setIsTurboSpin, setProgress } from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { EventTypes, eventManager } from '../../slotMachine/config';
import {
  isMobileDevice,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  queryParams,
  remoteStorage,
  wait,
} from '../../utils';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';
import { useAuth } from './useAuth';
import { useBonuses } from './useBonuses';
import { useLastBet } from './useLastBet';
import { useReplayBet } from './useReplayBet';
import { useSlotData } from './useSlotData';
import { useUserBalance } from './useUserBalance';

const LoadScreen: React.FC = () => {
  const progress = useReactiveVar(setProgress);
  const [isShowContent, setShowContent] = useState(true);
  const { getAuth } = useAuth();
  const { getUserBalance } = useUserBalance();
  const { getLastBet } = useLastBet();
  const { getPurchasableBonuses, checkBrokenGameMain } = useBonuses();
  const { getSlotData } = useSlotData();
  const { getReplayBetFreeSpins } = useReplayBet();

  useEffect(() => {
    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobileDevice());
        const { token, clientId } = parseQuery<IAuthInput>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data!.auth!.sessionId);
        rebuildStorageCache<IConfig>('config', {
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        if (queryParams.has('replayBetId')) {
          await getReplayBetFreeSpins();
        }
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGameMain();
        await getSlotData();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [...LOADER_TEXTURES, ...LOADER_SPRITE_TEXTURES, ...SPINE_LOADER_TEXTURES(setIsMobile())],
          process.env.PUBLIC_URL,
        );
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          restricted: false,
          isSoundEnabled: setIsSoundOn(),
          onSuspended: () => {},
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
        }).then(() => {
          eventManager.emit(EventTypes.SOUND_INITIALIZED);
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })

      .onComplete(async () => {
        setProgress({
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: false,
          });
          setTimeout(() => {
            setShowContent(false);
          }, 500);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles['loadScreenWrapper']}>
      <div className={styles['logo']}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles['companyLogo']}
        />
        <div className={styles['companyLogo_loaded']} style={{ height: `${progress.status}%` }}>
          <img draggable="false" alt="logoLoaded" src={Resources.getSource(ResourceTypes.logo)} />
        </div>
      </div>
    </div>
  );
};

export default LoadScreen;
