const gameRules = [
  { key: 'Rules.0', buyFeatureOnly: false },
  { key: 'Rules.1', buyFeatureOnly: false },
  { key: 'Rules.2', buyFeatureOnly: false },
  { key: 'Rules.3', buyFeatureOnly: false },
  { key: 'Rules.4', buyFeatureOnly: false },
  { key: 'Rules.5', buyFeatureOnly: false },
  { key: 'Rules.6', buyFeatureOnly: false },
  { key: 'Rules.7', buyFeatureOnly: false },
  { key: 'Rules.8', buyFeatureOnly: false },
  { key: 'Rules.9', buyFeatureOnly: false },
];

export default gameRules;
