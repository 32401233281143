import { SlotId } from '../../config';

export const getSlotIdNumber = (slotId: SlotId): number => {
  switch (slotId) {
    case SlotId.B:
      return 0;
    case SlotId.C:
      return 1;
    case SlotId.D:
      return 2;
    case SlotId.E:
      return 3;
    case SlotId.F:
      return 4;
    case SlotId.G:
      return 5;
    case SlotId.H:
      return 6;
    case SlotId.I:
      return 7;
    case SlotId.J:
      return 8;
    default:
      return 0;
  }
};

export const getSlotId = (slotId: string): SlotId => {
  switch (slotId) {
    case 'B':
      return SlotId.B;
    case 'C':
      return SlotId.C;
    case 'D':
      return SlotId.D;
    case 'E':
      return SlotId.E;
    case 'F':
      return SlotId.F;
    case 'G':
      return SlotId.G;
    case 'H':
      return SlotId.H;
    case 'I':
      return SlotId.I;
    case 'J':
      return SlotId.J;
    default:
      return SlotId.WL;
  }
};
