import { setReplayBet, setReplayFreeSpinBets } from '../../gql/cache';
import client from '../../gql/client';
import { betsByInitialRoundId } from '../../gql/query';

export const useReplayBet = () => {
  const getReplayBetFreeSpins = async () => {
    const replayBetFreeSpins = await client.query({
      query: betsByInitialRoundId,
      variables: {
        initialRoundId: setReplayBet(),
      },
    });
    if (replayBetFreeSpins.data.betsByInitialRoundId?.length) {
      const replayFreeSpins = replayBetFreeSpins.data.betsByInitialRoundId.map((e) => e.id);
      setReplayFreeSpinBets(replayFreeSpins);
      setReplayBet(replayBetFreeSpins.data.betsByInitialRoundId[0]!.id);
    }
  };
  return { getReplayBetFreeSpins };
};
