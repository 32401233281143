import { upgradeConfig } from '@pixi/particle-emitter';

import { ResourceTypes } from '../../resources.d';

export const glitterConfig = upgradeConfig(
  {
    alpha: {
      start: 0.9,
      end: 0.01,
    },
    scale: {
      start: 0.3,
      end: 0.8,
      minimumScaleMultiplier: 0.3,
    },
    color: {
      start: '#f0fcb6',
      end: '#a1ff3d',
    },
    speed: {
      start: 7,
      end: 5,
      minimumSpeedMultiplier: 0.8,
    },
    acceleration: {
      x: 0,
      y: -5,
    },
    maxSpeed: 200,
    startRotation: {
      min: -180,
      max: 0,
    },
    noRotation: false,
    rotationSpeed: {
      min: 0,
      max: 0,
    },
    lifetime: {
      min: 3,
      max: 5,
    },
    blendMode: 'normal',
    frequency: 0.004,
    emitterLifetime: -1,
    maxParticles: 2000,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    spawnType: 'rect',
    spawnRect: {
      x: -960,
      y: -540,
      w: 1920,
      h: 1080,
    },
  },
  [ResourceTypes.particle, ResourceTypes.sparks],
);
