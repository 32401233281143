import { MAPPED_EXPAND_SYMBOLS, MAPPED_SYMBOLS, SlotId, config } from '../../config';
import { ISettledBet } from '../../global.d';
import { makeFragmentData } from '../../gql';
import {
  BetBonusRewardFragment,
  BetCoinRewardFragment,
  HistoryNodeFragment,
  UserBonusFragment,
} from '../../gql/__generated__/graphql';
import { setBetAmount, setSlotConfig } from '../../gql/cache';
import { ISlotConfig } from '../../gql/d';
import { userBonusFragment } from '../../gql/fragment';
import {
  getExpandCount,
  getExpandSymbolCount,
  getExpandSymbolPosition,
  getNonNullableValue,
  getScatterCount,
  getScatterPosition,
  getSpSymbolByBonusId,
  getSpinResult5x3,
} from '../../utils';
import { createBonusReward, createCoinRewards } from '../../utils/helper/fragment';
import { REELS_AMOUNT } from '../config';
import { Combos, IWinLine, Icon } from '../d';

export const getReplayResult = (replayBet: HistoryNodeFragment, multiplier: number): ISettledBet => {
  setBetAmount(replayBet.coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  const slotConfig = setSlotConfig() as RecursiveNonNullable<ISlotConfig>;
  const reelSet = slotConfig.reels.find((reelSet) => reelSet.id === replayBet.reelSetId)!;
  const spinResult = getSpinResult5x3({
    reelPositions: getNonNullableValue(replayBet.result.reelPositions.slice(0, REELS_AMOUNT)),
    reelSet: reelSet,
    icons: slotConfig.icons,
  });
  const winLines = getWinPayLine(spinResult, replayBet, multiplier);
  console.log('winLine', winLines);
  const reward = getReward(spinResult, replayBet);
  const replayResult: ISettledBet = {
    bet: {
      ...replayBet,
      slotId: setSlotConfig().id,
      status: 'SETTLED',
      userBonusId: '',
      reelSet: reelSet,
      lineSet: setSlotConfig().lineSet,
      userBonus: {
        bonus: {
          reelSetId: replayBet.reelSetId,
        },
      },
    },
    paylines: winLines,
    balance: {
      placed: {
        amount: 0,
        currency: '',
      },
      settled: {
        amount: 0,
        currency: '',
      },
    },
    rewards: reward,
  };

  return replayResult;
};

const getWinPayLine = (spinResult: Icon[], replayBet: HistoryNodeFragment, multiplier: number): IWinLine[] => {
  const winLines: IWinLine[] = [];

  setSlotConfig().lines!.forEach((line, index) => {
    const symbols: Icon[] = [];
    let winPositions: number[] = [];
    let firstNotWildSymbol = SlotId.WL;
    let WL_length = 0;

    for (let i = 0; i < line!.length; i++) {
      const symbol: Icon = spinResult[line![i]!]!;
      if (firstNotWildSymbol === SlotId.WL) {
        firstNotWildSymbol = symbol.id;
      }

      if (firstNotWildSymbol === SlotId.WL || symbol.id === SlotId.WL) {
        if (firstNotWildSymbol === SlotId.WL) {
          WL_length++;
        }
        symbols.push(symbol);
        winPositions.push(line![i]!);
        continue;
      }

      if (firstNotWildSymbol !== symbol.id) {
        break;
      }

      symbols.push(symbol);
      winPositions.push(line![i]!);
    }

    if ((symbols.length >= 2 && firstNotWildSymbol <= SlotId.E) || symbols.length >= 3) {
      let winAmount = 0;
      const combo = config.miniPayTableData.find((d) => d.slug === MAPPED_SYMBOLS[firstNotWildSymbol])
        ?.combos as Combos;
      const Wl_combo = config.miniPayTableData.find((d) => d.slug === MAPPED_SYMBOLS[SlotId.WL])?.combos as Combos;
      if (
        WL_length >= 2 &&
        Wl_combo![REELS_AMOUNT - WL_length]!.multiplier > combo![REELS_AMOUNT - symbols.length]!.multiplier
      ) {
        winAmount = replayBet.coinAmount * Wl_combo![REELS_AMOUNT - WL_length]!.multiplier * multiplier;
        winPositions = winPositions.slice(0, WL_length);
      } else {
        winAmount = replayBet.coinAmount * combo![REELS_AMOUNT - symbols.length]!.multiplier * multiplier;
      }

      const line: IWinLine = {
        lineId: index,
        amount: winAmount,
        winPositions: winPositions,
      };
      winLines.push(line);
    }
  });

  const scatterCount = Math.max(...getScatterCount(spinResult));
  const scatterPosition = getScatterPosition(spinResult);
  if (scatterCount >= 3) {
    const combo = config.scatterTableData.find((d) => d.slug === MAPPED_SYMBOLS[SlotId.WL])?.combos as Combos;
    const line: IWinLine = {
      lineId: null,
      amount: replayBet.coinAmount * combo![REELS_AMOUNT - scatterCount]!.multiplier * multiplier,
      winPositions: scatterPosition,
    };
    winLines.push(line);
  }

  const spSlotId = replayBet.data.features.gameRoundStore
    ? getSpSymbolByBonusId(replayBet.data.features.gameRoundStore.bonusId)
    : SlotId.WL;
  const expandSymbolCount = Math.max(...getExpandCount(spinResult, spSlotId!));
  const expandSymbolPosition = getExpandSymbolPosition(spinResult, spSlotId);
  if ((expandSymbolCount >= 2 && spSlotId! <= SlotId.E) || expandSymbolCount >= 3) {
    const combo = config.ExpandTableData.find((d) => d.slug === MAPPED_EXPAND_SYMBOLS[spSlotId!])?.combos as Combos;
    const line: IWinLine = {
      lineId: null,
      amount: replayBet.coinAmount * combo![REELS_AMOUNT - expandSymbolCount]!.multiplier * multiplier,
      winPositions: expandSymbolPosition,
    };
    winLines.push(line);
  }

  if (winLines[winLines.length - 1]?.lineId === null) {
    const line: IWinLine = {
      lineId: null,
      amount: 0,
      winPositions: [],
    };
    winLines.push(line);
  }
  return winLines;
};

const getReward = (
  spinResult: Icon[],
  replayBet: HistoryNodeFragment,
): (
  | ({ __typename: 'BetBonusReward' } & { ' $fragmentRefs'?: { BetBonusRewardFragment: BetBonusRewardFragment } })
  | ({ __typename: 'BetCoinReward' } & { ' $fragmentRefs'?: { BetCoinRewardFragment: BetCoinRewardFragment } })
  | null
)[] => {
  const reward: (
    | ({ __typename: 'BetBonusReward' } & { ' $fragmentRefs'?: { BetBonusRewardFragment: BetBonusRewardFragment } })
    | ({ __typename: 'BetCoinReward' } & { ' $fragmentRefs'?: { BetCoinRewardFragment: BetCoinRewardFragment } })
    | null
  )[] = [];
  const winCoin = replayBet.result.winCoinAmount;

  const win = createCoinRewards(winCoin);
  reward.push(win);

  const spSlotId = replayBet.data.features.gameRoundStore
    ? getSpSymbolByBonusId(replayBet.data.features.gameRoundStore.bonusId)
    : SlotId.WL;
  const scatterCount = getScatterCount(spinResult);
  const expandSymbolCount = getExpandSymbolCount(spinResult, spSlotId);
  const gameRoundStore = replayBet.data.features.gameRoundStore;
  if (
    Math.max(...scatterCount) >= 3 ||
    (spSlotId! <= SlotId.E && Math.max(...expandSymbolCount) >= 2) ||
    (spSlotId! > SlotId.E && Math.max(...expandSymbolCount) >= 3)
  ) {
    const userBonus: UserBonusFragment = {
      id: gameRoundStore.bonusId,
      coinValue: replayBet.coinValue,
      coinAmount: replayBet.coinAmount,
      data: {
        count: 0,
        creditMultiplier: 1,
        debitMultiplier: 0,
        frbReferenceId: '',
        initialRoundId: '',
        storeCoinAmount: false,
        storeCoinValue: false,
      },
      betId: replayBet.id,
      totalWinAmount: winCoin,
      rounds: gameRoundStore.countFS,
      roundsPlayed: gameRoundStore.playedFS,
      bonusId: gameRoundStore.bonusId,
      status: 'ACTIVE',
      bonus: {
        id: gameRoundStore.bonusId,
        reelSetId: replayBet.reelSetId,
        type: 'FREE_SPIN',
        coinAmount: replayBet.coinAmount,
      },
      isFreeBet: false,
    };
    const bonus = createBonusReward(gameRoundStore.bonusId, makeFragmentData(userBonus, userBonusFragment));

    reward.push(bonus);
  }
  return reward;
};
