import { getFragmentData } from '../../gql/__generated__';
import {
  setBonuses,
  setBrokenBuyFeature,
  setBrokenGame,
  setBrokenGameReelSet,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setIsBuyFeaturePopupOpened,
  setPrevReelsPosition,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql/cache';
import client from '../../gql/client';
import { userBonusFragment } from '../../gql/fragment';
import { getBonuses, getReplayUserBonuses, getUserBonuses, slotBetGql } from '../../gql/query';
import { EventTypes, eventManager } from '../../slotMachine/config';
import { GameMode, freeRoundBonusId } from '../../slotMachine/config/bonusInfo';
import {
  calcActiveUserBonusTotalWinAmount,
  getGameModeByBonusId,
  getNonNullableValue,
  isFreeSpinsMode,
  queryParams,
} from '../../utils';

export const useBonuses = () => {
  const getPurchasableBonuses = async () => {
    const bonusData = await client.query({
      query: getBonuses,
      variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
      fetchPolicy: 'network-only',
    });
    setBonuses(bonusData.data.bonuses);
  };
  const checkBrokenGameMain = async () => {
    const query = queryParams.has('replayBetId') ? getReplayUserBonuses : getUserBonuses;
    const activeUserBonusData = await client.query({
      query,
      variables: {
        input: { status: 'ACTIVE', slotId: setSlotConfig().id },
      },
      fetchPolicy: 'network-only',
    });

    // its locally for testing.
    // ------------------------------
    // const originActiveUserBonusData = await client.query({
    //   query: getUserBonuses,
    //   variables: { input: { status: 'ACTIVE', slotId: setSlotConfig().id } },
    //   fetchPolicy: 'network-only',
    // });
    // const activeUserBonusData: ApolloQueryResult<GetUserBonusesQuery> = JSON.parse(
    //   JSON.stringify(originActiveUserBonusData),
    // );

    // const frbDummyData = makeFragmentData(
    //   {
    //     ...setCurrentBonus(),
    //     id: freeRoundBonusId,
    //     bonusId: freeRoundBonusId,
    //     coinAmount: 1,
    //     coinValue: 100,
    //     rounds: 2,
    //     totalWinAmount: 10000,
    //   },
    //   userBonusFragment,
    // );

    // activeUserBonusData.data.userBonuses!.push(frbDummyData);
    // ------------------------------

    const userBonuses = getFragmentData(userBonusFragment, getNonNullableValue(activeUserBonusData.data.userBonuses));
    const freeSpinsBonus = userBonuses.find((ub) => isFreeSpinsMode(getGameModeByBonusId(ub.bonusId)));

    if (userBonuses.length > 0) {
      if (GameMode.BUY_FEATURE === getGameModeByBonusId(userBonuses[0]!.bonusId)) {
        const specialBonus = userBonuses.find((ub) => ub.bonus!.type === 'SPECIAL_ROUND');
        setBrokenBuyFeature(true);
        setIsBuyFeaturePopupOpened(true);
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
        setCurrentBonusId(userBonuses[0]!.id);
        if (specialBonus) {
          setCurrentBonus({
            ...specialBonus,
            isActive: true,
            currentRound: specialBonus.roundsPlayed,
            totalRound: specialBonus.rounds + specialBonus.roundsPlayed,
          });
        }
      } else if (freeSpinsBonus) {
        setBrokenGame(true);

        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: freeSpinsBonus.roundsPlayed,
          totalRound: freeSpinsBonus.rounds + freeSpinsBonus.roundsPlayed,
        });
        const totalWin = calcActiveUserBonusTotalWinAmount(freeSpinsBonus);
        setCurrentFreeSpinsTotalWin(totalWin);

        if (setCurrentBonus().id != '') {
          const query = queryParams.has('replayBetId') ? getReplayUserBonuses : getUserBonuses;
          const res = await client.query({
            query,
            variables: { input: { id: setCurrentBonus().id } },
            fetchPolicy: 'network-only',
          });
          const userBonus = getFragmentData(userBonusFragment, getNonNullableValue(res.data.userBonuses![0]));
          const { betId } = userBonus;
          const bet = await client.query({
            query: slotBetGql,
            variables: { input: { id: betId! } },
            fetchPolicy: 'network-only',
          });

          setCurrentBonusId(setCurrentBonus().id);
          const lastBet = getNonNullableValue(setUserLastBetResult());
          setBrokenGameReelSet(lastBet.reelSetId);
          setPrevReelsPosition(lastBet.result.reelPositions);
          setCurrentBonus({
            ...setCurrentBonus(),
            rounds: bet.data.bet!.data.features.gameRoundStore.countFS,
            currentRound: bet.data.bet!.data.features.gameRoundStore.playedFS,
            totalRound: 0,
            bonus: {
              ...getNonNullableValue(setCurrentBonus().bonus),
              reelSetId: bet.data.bet!.reelSetId,
            },
          });
        }
      }

      const freeRoundBonus = userBonuses.find((e) => e.bonusId === freeRoundBonusId);
      if (freeRoundBonus && (!freeSpinsBonus || freeSpinsBonus.data?.frbReferenceId)) {
        const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
        setFreeRoundBonus({
          id: freeRoundBonus.id,
          bonusId: freeRoundBonus.bonusId,
          coinAmount: freeRoundBonus.coinAmount!,
          coinValue: freeRoundBonus.coinValue!,
          status: freeRoundBonus.status,
          rounds: freeRoundBonus.rounds,
          totalWinAmount,
          isActive: true,
          currentRound: 0,
        });
        setFreeRoundsTotalWin(totalWinAmount);
      }
    }
  };
  return { getPurchasableBonuses, checkBrokenGameMain };
};
