import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import { ISettledBet, MessageWinBannerProps, ReelSet } from '../../global.d';
import { HistoryNodeFragment } from '../../gql/__generated__/graphql';
import type Animation from '../animations/animation';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';
import { Icon } from '../d';
import { MeterKind, MeterLevel } from '../meter/config';

import { SlotMachineState } from '.';
import { GameMode } from './bonusInfo';

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  SPACE_KEY_CLOSE_SP_SYMBOL_LOT = 'spaceKeyCloseSpSymbolLot',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_UI_BUTTON = 'resizeUiButton',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_FADE = 'startFade',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREE_SPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_COUNT_UP = 'setIsCountUp',
  SET_IS_IN_TRANSITION = 'isInTransition',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_UPDATE_FREE_SPINS_COUNT = 'handleUpdateFreeSpinsCount',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_STATE = 'setState',
  PHOENIX_START = 'PhoenixStart',
  UI_VISIBLE = 'UiVisible',
  SPIN_END = 'SpinEnd',
  BONUS_END = 'BonusEnd',
  BONUS_WIN = 'BonusWin',
  WINDOW_NOT_ACTIVE = 'WindowNotActive',
  PLACE_BET_COMPLETED = 'PlaceBetCompleted',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  INIT_METER_SET = 'initMeterSet',
  SET_METER = 'setMeter',
  SET_METER_MULTIPLIER = 'setMeterMultiplier',
  HEART_SET_ADD = 'heartSet',
  HEART_SHOT = 'heartShot',
  SCENE_CHANGE_START = 'SceneChangeStart',
  SP_SYMBOL_LOT_START = 'SpSymbolLotStart',
  SP_SYMBOL_IF_VISIBLE = 'SpSymbolIfVisible',
  FREE_SPINS_DISPLAY_FLASH = 'FreeSpinsDisplayFlash',
  FREE_SPINS_DISPLAY_SHOW = 'FreeSpinsDisplayShow',
  SET_SP_SYMBOL = 'SetSpSymbol',
  SCATTER_HEART_STOCK = 'ScatterHeartStock',
  EXPAND_HEART_STOCK = 'ExpandHeartStock',
  START_METER_ADD_COUNT = 'startMeterAddCount',
  START_EXPAND_SYMBOL = 'startExpandSymbol',
  END_EXPAND_SYMBOL = 'endExpandSymbol',
  SET_INIT_SCATTER_METER = 'setInitScatterMeter',
  METER_ADD_HEART = 'meterAddHeart',
  START_EXPAND_SCENE = 'startExpandScene',
  SCATTER_WIN_END = 'scatterWinEnd',
  SET_REEL_FRAME_VISIBLE = 'SetReelFrameVisible',
  START_GET_AMOUNT_WIN = 'StartGetAmountWin',
  SET_SCENE_CHANGE = 'SetSceneChange',
  SET_BROKEN_BUY_FEATURE = 'setBrokenFeature',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  CLOSE_POPUP = 'closePopup',
  SET_IS_FADEOUT = 'setIsFadeOut',
  SET_IS_PHOENIX = 'setIsPhoenix',
  SET_IS_REPLAY = 'setIsReplay',
  REPLAY_SPIN = 'replaySpin',
  REPLAY_METER = 'replayMeter',
  SET_REPLAY_TEXT_VISIBILITY = 'setReplayTextVisibility',
  REPLAY_END = 'replayEnd',
  SET_IS_FREE_ROUND_BONUS = 'setIsFreeRoundBonus',
  SET_IS_POPUP_FREE_ROUNDS_OPENED = 'isOpenPopupFreeRounds',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE = 'updateFreeRoundBonusTotalWinValue',
  OPEN_POPUP_FREE_ROUNDS = 'openPopupFreeRounds',
  OPEN_POPUP_FREE_ROUNDS_END = 'openPopupFreeRoundsEnd',
  START_FADE_TRANSITION_FREE_ROUNDS_BONUS = 'startFadeTransitionFreeRoundBonus',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoPlay',
  START_FREE_SPINS = 'startFreeSpins',
  END_FREE_SPINS = 'endFreeSpins',
  SET_REPLAY_BET_ID = 'setReplayBetId',
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: (reel?: number) => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: (isExpand: boolean, value?: number) => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_UI_BUTTON]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (
    nextResult: ISettledBet,
    isTurboSpin: boolean,
    isScatter: boolean,
    isExpand: boolean,
  ) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_FADE]: (callback: () => void, finish: () => void) => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], constructorFlg?: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    scatterNo: number[],
    expandNo: number[],
    expandSlotId: SlotId,
    anticipationStartReelId: number,
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREE_SPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_COUNT_UP]: (isCountUp: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: () => void;
  [EventTypes.SET_STATE]: (state: SlotMachineState) => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.UI_VISIBLE]: (visible?: boolean) => void;
  [EventTypes.SPIN_END]: () => void;
  [EventTypes.BONUS_END]: () => void;
  [EventTypes.BONUS_WIN]: () => void;
  [EventTypes.WINDOW_NOT_ACTIVE]: () => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: () => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.INIT_METER_SET]: (setMultiPlierNum: number, setNum: MeterKind, isResume?: boolean) => void;
  [EventTypes.SET_METER]: (setMultiPlierNum: number, setNum: MeterLevel) => void;
  [EventTypes.SET_METER_MULTIPLIER]: (setMultiPlierNum: number) => void;
  [EventTypes.HEART_SET_ADD]: () => void;
  [EventTypes.HEART_SHOT]: () => void;
  [EventTypes.SCENE_CHANGE_START]: (isFreeSpins: boolean, reelPositions?: number[], reelSetId?: string) => void;
  [EventTypes.SP_SYMBOL_LOT_START]: () => void;
  [EventTypes.SP_SYMBOL_IF_VISIBLE]: () => void;
  [EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT]: () => void;
  [EventTypes.FREE_SPINS_DISPLAY_FLASH]: () => void;
  [EventTypes.FREE_SPINS_DISPLAY_SHOW]: (visible: boolean) => void;
  [EventTypes.SET_SP_SYMBOL]: (resource: SlotId | undefined) => void;
  [EventTypes.SCATTER_HEART_STOCK]: (scatterCount: number[], scatterPosition: number[]) => void;
  [EventTypes.EXPAND_HEART_STOCK]: (expandCount: number[]) => void;
  [EventTypes.START_METER_ADD_COUNT]: (isInit: boolean) => void;
  [EventTypes.START_EXPAND_SYMBOL]: (
    expandPosition: number[],
    expandHeightPosition: number[],
    symbolKind: number,
    isMax: boolean,
  ) => void;
  [EventTypes.END_EXPAND_SYMBOL]: () => void;
  [EventTypes.SET_INIT_SCATTER_METER]: (scatterCount: number[], scatterPosition: number[]) => void;
  [EventTypes.METER_ADD_HEART]: (initScatterCount: boolean, scatterCount: number[], expandCount: number[]) => void;
  [EventTypes.START_EXPAND_SCENE]: () => void;
  [EventTypes.SCATTER_WIN_END]: () => void;
  [EventTypes.SET_REEL_FRAME_VISIBLE]: (visible: boolean) => void;
  [EventTypes.START_GET_AMOUNT_WIN]: () => void;
  [EventTypes.SET_SCENE_CHANGE]: (isSceneChange: boolean) => void;
  [EventTypes.SET_BROKEN_BUY_FEATURE]: (isBrokenBuyFeature: boolean) => void;
  [EventTypes.FORCE_CLOSE_BUYFEATURE]: () => void;
  [EventTypes.CLOSE_POPUP]: () => void;
  [EventTypes.SET_IS_FADEOUT]: (isFadeOut: boolean) => void;
  [EventTypes.SET_IS_PHOENIX]: (isPhoenix: boolean) => void;
  [EventTypes.SET_IS_REPLAY]: (isReplay: boolean) => void;
  [EventTypes.REPLAY_SPIN]: (result: HistoryNodeFragment, cursor: string) => void;
  [EventTypes.REPLAY_METER]: (slotId: SlotId, multiPlier: number, heart: number, maxMult: number) => void;
  [EventTypes.SET_REPLAY_TEXT_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.REPLAY_END]: () => void;
  [EventTypes.SET_IS_FREE_ROUND_BONUS]: (isFreeRoundBonus: boolean) => void;
  [EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED]: (isPopupFreeRoundsOpened: boolean) => void;
  [EventTypes.UPDATE_FREE_ROUNDS_LEFT]: (rounds: number) => void;
  [EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS]: (rounds: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS_END]: (isExpired?: boolean) => void;
  [EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS]: (settings: {
    outDuration: number;
    inDuration: number;
    callback: () => void;
  }) => void;
  [EventTypes.FORCE_STOP_AUTOPLAY]: () => void;
  [EventTypes.START_FREE_SPINS]: () => void;
  [EventTypes.END_FREE_SPINS]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;
}

export const eventManager = new PIXI.utils.EventEmitter<keyof Events>() as TypedEmitter<Events>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as unknown as any).eventManager = eventManager; // Trick to proxy eventManager to cheats file
