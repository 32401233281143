import * as PIXI from 'pixi.js';

import SlotMachine from '..';
import { BgmSoundTypes } from '../../global.d';
import {
  setCurrentFreeSpinsTotalWin,
  setCurrentStage,
  setFreeRoundsTotalWin,
  setIsFadeOut,
  setIsFreeRoundBonus,
  setIsFreeSpinModeSceneChange,
  setIsInTransition,
  setIsSpSymbolLotOpening,
} from '../../gql/cache';
import { getGameModeByBonusId, updateCoinValueAfterBonuses } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import bgmControl from '../bgmControl/bgmControl';
import { EventTypes, FREE_SPINS_FADE_DURATION, FREE_SPINS_FADE_IN_DURATION, eventManager } from '../config';
import { GameMode } from '../config/bonusInfo';

class SceneChange extends PIXI.Container {
  private sprite: PIXI.Sprite;

  constructor() {
    super();

    this.interactive = false;
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.sprite.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SCENE_CHANGE_START, this.sceneChangeStart.bind(this));
    eventManager.addListener(EventTypes.START_FADE, this.startFade.bind(this));
    eventManager.addListener(
      EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS,
      this.startFreeRoundsEndFade.bind(this),
    );
  }

  private sceneChangeStart(isFreeSpins: boolean, _reelPositions?: number[], _reelSetId?: string) {
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, FREE_SPINS_FADE_DURATION);
    const fadeIn = this.getFadeAnimation(0, FREE_SPINS_FADE_IN_DURATION);

    animationChain.addOnStart(() => {
      setIsFreeSpinModeSceneChange(true);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, true);
    });
    animationChain.addOnComplete(() => {
      setIsFreeSpinModeSceneChange(false);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, false);
    });

    let bgm: BgmSoundTypes;
    if (isFreeSpins) {
      bgm = BgmSoundTypes.BB_Start_Before;
    } else {
      bgm = BgmSoundTypes.BASE;
      setCurrentStage(0);
    }

    fadeOut.addOnComplete(() => {
      if (isFreeSpins) {
        setIsSpSymbolLotOpening(true);
        eventManager.emit(EventTypes.SP_SYMBOL_LOT_START);
      }
      this.changeFreeSpinMode(isFreeSpins, _reelPositions, _reelSetId);
      bgmControl.playBgm(bgm);
    });

    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();
  }

  private changeFreeSpinMode(isFreeSpins: boolean, _reelPositions?: number[], _reelSetId?: string) {
    const freeSpinsBonus = SlotMachine.getInstance().getFreeSpinBonus();
    const gameMode = getGameModeByBonusId(freeSpinsBonus ? freeSpinsBonus.bonus!.id : '');
    let mode = gameMode;

    let reelPositions = _reelPositions!;
    let reelSetId = _reelSetId!;
    if (!isFreeSpins) {
      mode = GameMode.REGULAR;
      reelPositions = _reelPositions!;
      reelSetId = _reelSetId!;
      updateCoinValueAfterBonuses();
      eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
    }
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode,
      reelPositions,
      reelSetId,
    });
    if (isFreeSpins) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      if (setIsFreeRoundBonus()) {
        eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      }
    }
  }

  private startFade(callback: () => void, finish: () => void): void {
    const animationChain: AnimationChain = new AnimationChain();
    animationChain.addOnStart(() => {
      setIsFadeOut(true);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, true);
    });
    animationChain.addOnComplete(() => {
      setIsFadeOut(false);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, false);
    });
    const fadeOut = this.getFadeAnimation(1, FREE_SPINS_FADE_DURATION / 2);
    fadeOut.addOnComplete(callback);
    const fadeIn = this.getFadeAnimation(0, FREE_SPINS_FADE_DURATION / 2);
    fadeIn.addOnComplete(finish);
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();
  }

  private startFreeRoundsEndFade(settings: { outDuration: number; inDuration: number; callback: () => void }) {
    this.interactive = true;
    setIsInTransition(true);

    this.startFade(settings.callback, () => {
      this.interactive = false;
      setIsInTransition(false);
    });
  }

  private getFadeAnimation(alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: alpha === 1 ? 0 : 1,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  private resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }
}
export default SceneChange;
