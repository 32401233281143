import { setCurrency } from '../../gql/cache';
import client from '../../gql/client';
import { getUserGql, replayGetUserGql } from '../../gql/query';
import { queryParams } from '../../utils';

export const useUserBalance = () => {
  const getUserBalance = async () => {
    const query = queryParams.has('replayBetId') ? replayGetUserGql : getUserGql;
    const userBalance = await client.query({
      query,
      fetchPolicy: 'network-only',
    });
    setCurrency(userBalance.data.user.balance?.currency);
  };
  return { getUserBalance };
};
